
import Vue from "vue";
import {
  VCard,
  VCardActions,
  VBtn,
  VIcon,
  VSpacer,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent
} from "vuetify/lib";

export default Vue.extend({
  name: "SearchListForm",
  components: {
    VCard,
    VCardActions,
    VBtn,
    VIcon,
    VSpacer,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent
  },
  props: {},
  data() {
    return {
      expansionPanels: 0
    };
  }
});
