export class JyutyuMeisaiKey {
  denpyoNo: number;
  tokuiId: number;
  gyo: number;

  constructor(props: { denpyoNo: number; tokuiId: number; gyo: number }) {
    this.denpyoNo = props.denpyoNo;
    this.tokuiId = props.tokuiId;
    this.gyo = props.gyo;
  }
}

export class JyutyuMeisai extends JyutyuMeisaiKey {
  santiId: number | null;
  santiNm: string | null;
  shohinId: number | null;
  edano: number | null;
  shohinNm: string | null;
  kikakuId: number | null;
  kikakuNm: string | null;
  irisuu: number | null;
  hattyusuu: number | null;
  taniId: number | null;
  taniNm: string | null;
  jissuu: number | null;
  tanka: number | null;
  bikou: string | null;
  tantoId: number | null;
  tantoNm: string | null;

  constructor(props: {
    denpyoNo: number;
    tokuiId: number;
    gyo: number;
    santiId?: number | null;
    santiNm?: string | null;
    shohinId?: number | null;
    edano?: number | null;
    shohinNm?: string | null;
    kikakuId?: number | null;
    kikakuNm?: string | null;
    irisuu?: number | null;
    hattyusuu?: number | null;
    taniId?: number | null;
    taniNm?: string | null;
    jissuu?: number | null;
    tanka?: number | null;
    bikou?: string | null;
    tantoId?: number | null;
    tantoNm?: string | null;
  }) {
    super(props);
    this.santiId = props.santiId ? props.santiId : null;
    this.santiNm = props.santiNm ? props.santiNm : null;
    this.shohinId = props.shohinId ? props.shohinId : null;
    this.edano = props.edano ? props.edano : null;
    this.shohinNm = props.shohinNm ? props.shohinNm : null;
    this.kikakuId = props.kikakuId ? props.kikakuId : null;
    this.kikakuNm = props.kikakuNm ? props.kikakuNm : null;
    this.irisuu = props.irisuu ? props.irisuu : null;
    this.hattyusuu = props.hattyusuu ? props.hattyusuu : null;
    this.taniId = props.taniId ? props.taniId : null;
    this.taniNm = props.taniNm ? props.taniNm : null;
    this.jissuu = props.jissuu ? props.jissuu : null;
    this.tanka = props.tanka ? props.tanka : null;
    this.bikou = props.bikou ? props.bikou : null;
    this.tantoId = props.tantoId ? props.tantoId : null;
    this.tantoNm = props.tantoNm ? props.tantoNm : null;
  }
}
