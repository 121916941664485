
import Vue, { PropType } from "vue";
import DatePicker from "@/components/DatePicker.vue";
import { VRow, VCol, VForm, VAutocomplete } from "vuetify/lib";
import Api from "@/mixins/api";
import { PulldownResponse } from "@/models/transaction/PulldownResponse";
import { TokuiGroup } from "@/models/transaction/TokuiGroup";

const api = new Api();

export class BatchEntrySearchFormCls {
  dateFrom: string | null;
  dateTo: string | null;
  client: TokuiGroup | null;
  constructor(props: {
    dateFrom: string | null;
    dateTo: string | null;
    client: TokuiGroup | null;
  }) {
    this.dateFrom = props.dateFrom;
    this.dateTo = props.dateTo;
    this.client = props.client;
  }
}

export default Vue.extend({
  name: "BatchEntrySearchForm",
  components: { VRow, VCol, VForm, VAutocomplete, DatePicker },
  props: {
    value: {
      type: Object as PropType<BatchEntrySearchFormCls>,
      required: true
    }
  },
  computed: {
    modelValue: {
      get(): BatchEntrySearchFormCls {
        return this.value;
      },
      set(newValue: BatchEntrySearchFormCls) {
        this.$emit("input", newValue);
      }
    }
  },
  data() {
    return {
      items: [] as TokuiGroup[]
    };
  },
  async mounted() {
    const pulldown = await api.$get<PulldownResponse[]>(
      api.Paths.jyutyuTokuiPulldown
    );
    this.items = pulldown.map(
      e =>
        new TokuiGroup({
          tokuiId: Number(e.option1),
          groupId: Number(e.option2),
          name: e.name
        })
    );
  }
});
