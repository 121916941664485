
import Vue, { PropType } from "vue";
import { VIcon, VCardText } from "vuetify/lib";

export const BatchEntryTableInput = {
  text: "text",
  number: "number"
} as const;
export type BatchEntryTableInputType = typeof BatchEntryTableInput[keyof typeof BatchEntryTableInput];

export type BatchEntryTableHeaderType = {
  text: string;
  value: string | number;
  type?: BatchEntryTableInputType;
  width?: string;
  disabled?: boolean;
};

export type BatchEntryTableType = Record<number, number | null> &
  Record<number | string, number | string | boolean | null> & {
    santiNm: string | null;
    shohinNm: string | null;
    kikakuNm: string | null;
    tanka: number | null;
    tantoNm: string | null;
    gyo: number;
    isAddRow: boolean;
  };

export default Vue.extend({
  name: "BatchEntryTable",
  components: { VIcon, VCardText },
  props: {
    value: {
      type: Array as PropType<BatchEntryTableType[]>,
      required: true
    },
    headers: {
      type: Array as PropType<BatchEntryTableHeaderType[]>,
      required: true
    }
  },
  computed: {
    modelValue: {
      get(): BatchEntryTableType[] {
        return this.value;
      },
      set(newValue: BatchEntryTableType[]) {
        this.$emit("input", newValue);
      }
    },
    isClient: {
      get() {
        return this.$store.state.authority === "1";
      }
    }
  },
  methods: {
    onAddRow(index: number) {
      const newRow: BatchEntryTableType = {
        gyo: index,
        santiNm: null,
        shohinNm: null,
        kikakuNm: null,
        tanka: null,
        tantoNm: null,
        isAddRow: true
      };
      this.modelValue = [
        ...this.modelValue.slice(0, index),
        ...[newRow],
        ...this.modelValue.slice(index)
      ];
    },
    onDeleteRow(index: number) {
      this.modelValue.splice(index, 1);
    },
    onFocus(tr: number, td: number) {
      const ele = this.findSabledElement(tr, td);
      setTimeout(() => ele?.focus(), 150);
    },
    findSabledElement(
      trIndex: number,
      tdIndex: number
    ): HTMLInputElement | null {
      const tbody = this.$refs.tbody as HTMLTableElement;
      const trList = [...tbody.rows];
      const tr = trList.find(e => e.rowIndex === trIndex + 1);
      if (tr) {
        const tdList = [...tr.cells];
        const td = tdList.find(e => e.cellIndex === tdIndex);
        console.log(trIndex, "trIndex");
        console.log(tdIndex, "tdIndex");
        if (td) {
          const input = td.querySelector("input");
          if (input && !input.disabled) {
            return input;
          } else {
            return this.findSabledElement(trIndex, tdIndex + 1);
          }
        } else {
          if (tdIndex < 0) {
            return this.findSabledElement(trIndex - 1, 0);
          } else {
            return this.findSabledElement(trIndex + 1, 0);
          }
        }
      }
      return null;
    },
    isInputControl(h: BatchEntryTableHeaderType, t: BatchEntryTableType) {
      return this.isClient && h.disabled;
    },
    isActionControl(t: BatchEntryTableType) {
      return this.isClient && !t.isAddRow;
    }
  }
});
