
import Vue, { PropType } from "vue";
import BatchEntryForm, {
  BatchEntryFormCls
} from "@/components/batchEntry/BatchEntryForm.vue";
import {
  VRow,
  VCol,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent
} from "vuetify/lib";

interface VForm extends Vue {
  reset(): void;
  resetValidation(): void;
  validate(): boolean;
}

export default Vue.extend({
  name: "BatchEntryExpansionPanel",
  components: {
    VRow,
    VCol,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent,
    BatchEntryForm
  },
  props: {
    value: {
      type: Object as PropType<BatchEntryFormCls>,
      required: true
    }
  },
  computed: {
    modelValue: {
      get(): BatchEntryFormCls {
        return this.value;
      },
      set(newValue: BatchEntryFormCls) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    validate() {
      const form = this.$refs.form as VForm;
      return form.validate();
    }
  }
});
