export class JyutyuHedderKey {
  denpyoNo: number;

  constructor(props: { denpyoNo: number }) {
    this.denpyoNo = props.denpyoNo;
  }
}

export class JyutyuHedder extends JyutyuHedderKey {
  denpyoDate?: Date | null;
  userId?: string | null;
  tokuiId?: number | null;
  tokuiGroup?: number | null;
  indexId?: number | null;
  biko?: string | null;
  createUser?: string | null;
  createTs?: number | null;
  updateUser?: string | null;
  updateTs?: number | null;
  kaiukeId?: number | null;
  formatId?: number | null;

  constructor(props: {
    denpyoNo: number;
    denpyoDate?: Date | null;
    userId?: string | null;
    tokuiId?: number | null;
    tokuiGroup?: number | null;
    indexId?: number | null;
    biko?: string | null;
    createUser?: string | null;
    createTs?: number | null;
    updateUser?: string | null;
    updateTs?: number | null;
    kaiukeId?: number | null;
    formatId?: number | null;
  }) {
    super(props);
    this.denpyoDate = props.denpyoDate;
    this.userId = props.userId;
    this.tokuiId = props.tokuiId;
    this.tokuiGroup = props.tokuiGroup;
    this.indexId = props.indexId;
    this.biko = props.biko;
    this.createUser = props.createUser;
    this.createTs = props.createTs;
    this.updateUser = props.updateUser;
    this.updateTs = props.updateTs;
    this.kaiukeId = props.kaiukeId;
    this.formatId = props.formatId;
  }
}
