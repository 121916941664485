export class JyutyuformatHedderKey {
  formatId: number;

  constructor(props: { formatId: number }) {
    this.formatId = props.formatId;
  }
}

export class JyutyuformatHedder extends JyutyuformatHedderKey {
  userId?: string | null;
  tokuiId?: number | null;
  tokuiGroup?: number | null;
  indexId?: number | null;
  indexName?: string | null;
  isValid?: string | null;
  createUser?: string | null;
  createTs?: number | null;
  updateUser?: string | null;
  updateTs?: number | null;
  kaiukeId?: number | null;

  constructor(props: {
    formatId: number;
    userId?: string | null;
    tokuiId?: number | null;
    tokuiGroup?: number | null;
    indexId?: number | null;
    indexName?: string | null;
    isValid?: string | null;
    createUser?: string | null;
    createTs?: number | null;
    updateUser?: string | null;
    updateTs?: number | null;
    kaiukeId?: number | null;
  }) {
    super(props);
    this.userId = props.userId;
    this.tokuiId = props.tokuiId;
    this.tokuiGroup = props.tokuiGroup;
    this.indexId = props.indexId;
    this.indexName = props.indexName;
    this.isValid = props.isValid;
    this.createUser = props.createUser;
    this.createTs = props.createTs;
    this.updateUser = props.updateUser;
    this.updateTs = props.updateTs;
    this.kaiukeId = props.kaiukeId;
  }
}
