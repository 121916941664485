
import Vue from "vue";
import { VBtn, VIcon, VSnackbar } from "vuetify/lib";

export default Vue.extend({
  name: "WarnSnackbar",
  components: { VBtn, VIcon, VSnackbar },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    modelValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    }
  }
});
