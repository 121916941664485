
import Vue, { PropType } from "vue";
import { VCard, VDivider } from "vuetify/lib";
import SearchListForm from "@/components/searchList/SearchListForm.vue";
import SearchListTable from "@/components/searchList/SearchListTable.vue";

export default Vue.extend({
  name: "SearchList",
  components: { VCard, VDivider, SearchListForm, SearchListTable },
  props: {
    value: {
      type: Array as PropType<any[]>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    }
  },
  computed: {
    modelValue: {
      get(): any[] {
        return this.value;
      },
      set(newValue: any[]) {
        this.$emit("input", newValue);
      }
    }
  }
});
