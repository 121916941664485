
import Vue from "vue";
import moment from "moment";
import SearchList from "@/components/searchList/SearchList.vue";
import Api from "@/mixins/api";
import BatchEntrySearchForm, {
  BatchEntrySearchFormCls
} from "@/components/batchEntry/search/BatchEntrySearchForm.vue";

import { JyutyuHedder } from "@/models/transaction/JyutyuHedder";
import { BatchEntrySearchRequest } from "@/models/batchEntry/BatchEntrySearchRequest";

import showDialogs from "@/mixins/showDialogs";
const dialog = new showDialogs();

const api = new Api();

const dateFormat = "YYYY-MM-DD";

const dateFrom = moment().format(dateFormat);
const dateTo = moment()
  .add({ week: 1 })
  .format(dateFormat);

export class BatchEntrySearchListCls {
  denpyoNo: number;
  denpyoDate: string | null;
  tokuiName: string | null;
  biko: string | null;
  tokuiId: number;
  tokuiGroup: number;
  formatId: number;
  kaiukeId: number | null;

  constructor(props: {
    denpyoNo: number;
    denpyoDate: string;
    tokuiName: string;
    formatnm: string;
    biko: string;
    tokuiId: number;
    tokuiGroup: number;
    formatId: number;
    kaiukeId: number | null;
  }) {
    this.denpyoNo = props.denpyoNo;
    this.denpyoDate = props.denpyoDate;
    this.tokuiName = props.tokuiName;
    this.biko = props.biko;
    this.tokuiId = props.tokuiId;
    this.tokuiGroup = props.tokuiGroup;
    this.formatId = props.formatId;
    this.kaiukeId = props.kaiukeId;
  }
}

const BatchEntrySearchListHeaders = [
  {
    text: "伝票番号",
    value: "denpyoNo"
  },
  {
    text: "伝票日付",
    value: "denpyoDate"
  },
  {
    text: "得意先",
    value: "tokuiName"
  },
  {
    text: "摘要",
    value: "biko"
  },
  {
    text: "actions",
    value: "actions"
  }
] as const;

export default Vue.extend({
  name: "BatchEntrySearchList",
  components: { SearchList, BatchEntrySearchForm },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchListHeaders: BatchEntrySearchListHeaders,
      searchForm: new BatchEntrySearchFormCls({
        dateFrom: dateFrom,
        dateTo: dateTo,
        client: null
      }),
      selectLists: [] as BatchEntrySearchListCls[],
      searchList: [] as BatchEntrySearchListCls[]
    };
  },
  computed: {
    searchRequest: {
      get(): BatchEntrySearchRequest {
        const client = this.searchForm.client;
        return {
          dateFrom: this.searchForm.dateFrom,
          dateTo: this.searchForm.dateTo,
          tokuiId: client ? client.tokuiId : null,
          tokuiGroup: client ? client.groupId : null
        };
      }
    }
  },
  methods: {
    async onSelect() {
      this.searchList = await api.$post<BatchEntrySearchListCls[]>(
        api.Paths.batchEntry.get,
        this.searchRequest
      );
    },
    async onSingleDelete(item: BatchEntrySearchListCls) {
      const request = [new JyutyuHedder({ denpyoNo: item.denpyoNo })];
      const confirm = await dialog.$confirm(
        "選択中の伝票を削除しようとしています。よろしいですか？",
        "削除"
      );
      if (!confirm) return;
      await api.$post(api.Paths.batchEntry.bulkDelete, request);
      await this.onSelect();
    },
    async onDelete() {
      const request = this.selectLists.map(
        e => new JyutyuHedder({ denpyoNo: e.denpyoNo })
      );
      if (request.length == 0) {
        dialog.$info("削除対象の伝票が選択されていません。", "データ無し");
        return;
      }
      const confirm = await dialog.$confirm(
        "選択中の伝票を削除しようとしています。よろしいですか？",
        "削除"
      );
      if (!confirm) return;
      await api.$post(api.Paths.batchEntry.bulkDelete, request);
      await this.onSelect();
    }
  },
  async mounted() {
    await this.onSelect();
  }
});
