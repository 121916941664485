<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="Format"
        readonly
        v-bind="attrs"
        v-on="on"
        :label="label"
        :outlined="outlined"
        :prepend-icon="previous ? 'mdi-chevron-left' : prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        :append-icon="appendIcon"
        :append-outer-icon="next ? 'mdi-chevron-right' : appendOuterIcon"
        :rules="rules"
        :disabled="disabled"
        :clearable="clearable"
        :dense="dense"
        :hide-details="hideDetails"
        @click:prepend="
          e => {
            $emit('prepend', e);
            click(false);
          }
        "
        @click:prepend-innner="e => $emit('prepend-innner', e)"
        @click:append="e => $emit('append', e)"
        @click:append-outer="
          e => {
            $emit('append-outer', e);
            click(true);
          }
        "
        @click:clear="date = null"
      ></v-text-field>
    </template>
    <v-date-picker
      :class="Type"
      v-model="date"
      @input="visible = false"
      locale="ja-jp"
      :day-format="date => new Date(date).getDate()"
      :type="Type"
      @change="change"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: String,
    week: Number, //Sync
    format: String, //Sync Only
    clearable: Boolean, //Null許容
    label: String,
    prependIcon: String,
    prependInnerIcon: String,
    prependIconNone: Boolean,
    appendIcon: String,
    appendOuterIcon: String,
    outlined: Boolean,
    width: { type: [Number, String] },
    maxWidth: { type: String },
    rules: Array,
    disabled: Boolean,
    type: { type: String, default: "date" }, //date,month,week
    next: Boolean,
    previous: Boolean,
    dense: Boolean,
    hideDetails: [Boolean, String]
  },
  data() {
    return {
      visible: false,
      date: "",
      weekCnt: 0
    };
  },
  computed: {
    Date() {
      return !this.value ? this.date : this.value;
    },
    Type() {
      if (["week", "day"].includes(this.type)) return "date";

      return this.type;
    },
    Format() {
      if (!this.Date) return this.Date;

      const date = new Date(this.Date);

      let format = `${date.getFullYear()} 年 ${date.getMonth() + 1} 月`;

      switch (this.type) {
        case "date":
        case "day":
          format += ` ${date.getDate()} 日`;
          break;

        case "week":
          format += ` 第 ${this.weekCnt} 週`;
          break;
      }
      this.$emit("update:format", format);
      return format;
    }
  },
  watch: {
    value: function(v) {
      if (this.date !== v) this.date = v;
    },
    date: function(v) {
      this.$emit("input", v);
      this.setWeek();
    },
    week: function(v) {
      if (this.weekCnt !== v) this.weekCnt = v;
    },
    weekCnt: function(v) {
      this.$emit("update:week", v);
    }
  },
  methods: {
    change(e) {
      console.log("change", e);
      if (e.length < 8) {
        e += "-01";
        this.date = e;
      }
      this.$emit("change", e);
    },
    click(next) {
      console.log("click", next);

      if (!this.date || (next && !this.next) || (!next && !this.previous)) {
        console.log("return");
        return;
      }

      const date = new Date(this.date);
      const add = next ? 1 : -1;

      switch (this.type) {
        case "date":
          date.setDate(date.getDate() + add);
          break;
        case "month":
          date.setMonth(date.getMonth() + add);
          break;
        case "week":
          do {
            date.setDate(date.getDate() + add);
          } while (!(date.getDate() == 1 || date.getDay() == 1));
          break;
      }

      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      this.date = date.toISOString().substr(0, 10);
    },
    setWeek() {
      const date = new Date(this.date);
      this.weekCnt = Math.floor((date.getDate() - date.getDay() + 12) / 7);
    }
  },
  mounted() {
    if (this.value) {
      this.date = this.value;
      console.log("value", this.date);
    }
  }
};
</script>

<style lang="scss">
.date {
  .v-date-picker-table.v-date-picker-table--date
    > table
    > tbody
    tr
    td:nth-child(7)
    .v-btn__content {
    color: blue;
  }

  td:nth-child(1) .v-btn__content {
    color: red;
  }
}
</style>
