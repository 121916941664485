
import Vue, { PropType } from "vue";
import {
  VCard,
  VCardTitle,
  VIcon,
  VSpacer,
  VTextField,
  VDataTable
} from "vuetify/lib";

export default Vue.extend({
  name: "SearchListTable",
  components: { VCard, VCardTitle, VIcon, VSpacer, VTextField, VDataTable },
  props: {
    value: {
      type: Array as PropType<any[]>,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    itemKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: ""
    };
  },
  computed: {
    modelValue: {
      get(): any[] {
        return this.value;
      },
      set(newValue: any[]) {
        this.$emit("input", newValue);
      }
    }
  }
});
