import { JyutyuHedder } from "@/models/transaction/JyutyuHedder";
import { JyutyuMeisai } from "@/models/transaction/JyutyuMeisai";

export class BatchEntryInsertRequest {
  header: JyutyuHedder;
  meisai: JyutyuMeisai[];

  constructor(props: { header: JyutyuHedder; meisai: JyutyuMeisai[] }) {
    this.header = props.header;
    this.meisai = props.meisai;
  }
}
