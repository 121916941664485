export class KaiukeKey {
  id: number;

  constructor(id: number) {
    this.id = id;
  }
}

export class Kaiuke extends KaiukeKey {
  ckbn?: number | null;
  ukeno: string;
  datetime1?: Date | null;
  datetime2?: Date | null;

  constructor(props: {
    id: number;
    ckbn?: number | null;
    ukeno: string;
    datetime1?: Date | null;
    datetime2?: Date | null;
  }) {
    super(props.id);
    this.id = props.id;
    this.ckbn = props.ckbn;
    this.ukeno = props.ukeno;
    this.datetime1 = props.datetime1;
    this.datetime2 = props.datetime2;
  }
}
