export class JyutyuformatMeisaiKey {
  formatId: number;
  tokuiId: number;
  gyo: number;

  constructor(props: { formatId: number; tokuiId: number; gyo: number }) {
    this.formatId = props.formatId;
    this.tokuiId = props.tokuiId;
    this.gyo = props.gyo;
  }
}

export class JyutyuformatMeisai extends JyutyuformatMeisaiKey {
  santiId: number | null;
  shohinId: number | null;
  edano: number | null;
  shohinNm: string | null;
  kikakuId: number | null;
  irisuu: number | null;
  hattyusuu: number | null;
  taniId: number | null;
  jissuu: number | null;
  tanka: number | null;
  bikou: string | null;
  tantoId: number | null;

  constructor(props: JyutyuformatMeisaiKey);
  constructor(props: {
    formatId: number;
    tokuiId: number;
    gyo: number;
    santiId?: number;
    shohinId?: number;
    edano?: number;
    shohinNm?: string;
    kikakuId?: number;
    irisuu?: number;
    hattyusuu?: number;
    taniId?: number;
    jissuu?: number;
    tanka?: number;
    bikou?: string;
    tantoId?: number;
  }) {
    super(props);

    const {
      santiId = null,
      shohinId = null,
      edano = null,
      shohinNm = null,
      kikakuId = null,
      irisuu = null,
      hattyusuu = null,
      taniId = null,
      jissuu = null,
      tanka = null,
      bikou = null,
      tantoId = null
    } = props;

    this.santiId = santiId;
    this.shohinId = shohinId;
    this.edano = edano;
    this.shohinNm = shohinNm;
    this.kikakuId = kikakuId;
    this.irisuu = irisuu;
    this.hattyusuu = hattyusuu;
    this.taniId = taniId;
    this.jissuu = jissuu;
    this.tanka = tanka;
    this.bikou = bikou;
    this.tantoId = tantoId;
  }
}
