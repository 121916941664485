
import Vue, { PropType } from "vue";
import FullscreenDialog from "@/components/dialog/FullscreenDialog.vue";
import BatchEntryExpansionPanel from "@/components/batchEntry/BatchEntryExpansionPanel.vue";
import BatchEntryTable, {
  BatchEntryTableHeaderType,
  BatchEntryTableType
} from "@/components/batchEntry/BatchEntryTable.vue";
import { BatchEntryFormCls } from "@/components/batchEntry/BatchEntryForm.vue";
import WarnSnackbar from "../snackbar/WarnSnackbar.vue";
import { JyutyuMeisai } from "@/models/transaction/JyutyuMeisai";

interface VForm extends Vue {
  reset(): void;
  resetValidation(): void;
  validate(): boolean;
}

export default Vue.extend({
  name: "BatchEntryDialog",
  components: {
    FullscreenDialog,
    BatchEntryExpansionPanel,
    BatchEntryTable,
    WarnSnackbar
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<BatchEntryTableType[]>,
      required: true
    },
    headers: {
      type: Array as PropType<BatchEntryTableHeaderType[]>,
      required: true
    },
    form: {
      type: Object as PropType<BatchEntryFormCls>,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      content: ""
    };
  },
  computed: {
    modelValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    },
    modelItems: {
      get(): BatchEntryTableType[] {
        return this.items;
      },
      set(newValue: BatchEntryTableType[]) {
        this.$emit("update:items", newValue);
      }
    },
    modelForm: {
      get(): BatchEntryFormCls {
        return this.form;
      },
      set(newValue: BatchEntryFormCls) {
        this.$emit("update:form", newValue);
      }
    }
  },
  methods: {
    onAddRow(index: number) {
      const newRow: BatchEntryTableType = {
        gyo: index,
        santiNm: null,
        shohinNm: null,
        kikakuNm: null,
        tanka: null,
        tantoNm: null,
        isAddRow: true
      };
      this.modelItems = [
        ...this.modelItems.slice(0, index),
        ...[newRow],
        ...this.modelItems.slice(index)
      ];
    },
    onSubmit() {
      const form = this.$refs.form as VForm;
      if (!form.validate()) {
        this.content = "入力に誤りがあります。入力内容を確認してください。";
        this.isOpen = true;
        return;
      }
      if (this.items.length === 0) {
        this.content = "明細に１行もデータが入力されていません。";
        this.isOpen = true;
        return;
      }
      this.$emit("submit");
    },
    onFormat(value: JyutyuMeisai[]) {
      if (!value || value.length === 0) {
        this.content = "フォーマットが選択されていません。";
        this.isOpen = true;
        return;
      }
      this.$emit("format", value);
    }
  }
});
