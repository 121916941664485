export class TokuiGroupKey {
  groupId: number;
  tokuiId: number;

  constructor(props: { groupId: number; tokuiId: number }) {
    this.groupId = props.groupId;
    this.tokuiId = props.tokuiId;
  }
}

export class TokuiGroup extends TokuiGroupKey {
  name: string | null;
  abbreviation: string | null;

  constructor(props: {
    groupId: number;
    tokuiId: number;
    name?: string;
    abbreviation?: string;
  }) {
    super(props);
    const { name = null, abbreviation = null } = props;

    this.name = name;
    this.abbreviation = abbreviation;
  }
}

export class TokuiGroupExtention extends TokuiGroup {
  bun1: number;

  constructor(props: {
    groupId: number;
    tokuiId: number;
    name?: string;
    abbreviation?: string;
    bun1: number;
  }) {
    super(props);
    this.bun1 = props.bun1;
  }
}
