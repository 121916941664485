
import Vue from "vue";
import {
  VDialog,
  VCard,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn,
  VIcon
} from "vuetify/lib";

export default Vue.extend({
  name: "FullscreenDialog",
  components: {
    VDialog,
    VCard,
    VToolbar,
    VToolbarTitle,
    VSpacer,
    VBtn,
    VIcon
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    modelValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    }
  }
});
