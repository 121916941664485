
import Vue, { PropType } from "vue";
import { VRow, VCol, VForm, VTextField, VAutocomplete } from "vuetify/lib";
import DatePicker from "@/components/DatePicker.vue";
import Api from "@/mixins/api";
import { PulldownResponse } from "@/models/transaction/PulldownResponse";
import {
  TokuiGroup,
  TokuiGroupKey,
  TokuiGroupExtention
} from "@/models/transaction/TokuiGroup";
import { JyutyuformatMeisaiKey } from "@/models/transaction/JyutyuformatMeisai";
import { JyutyuMeisai } from "@/models/transaction/JyutyuMeisai";
import { JyutyuformatHedder } from "@/models/transaction/JyutyuformatHedder";
import { InputRules } from "@/models/Rules";
import { Kaiuke } from "@/models/transaction/Kaiuke";

const api = new Api();

export class BatchEntryFormCls {
  denpyoNo: number;
  denpyoDate: string | null;
  client: TokuiGroupKey | null;
  formatId: string | null;
  kaiukeId: number | null;
  biko: string | null;
  constructor(props: {
    denpyoNo: number;
    denpyoDate?: string | null;
    client?: TokuiGroupKey | null;
    formatId?: string | null;
    kaiukeId?: number | null;
    biko?: string | null;
  }) {
    this.denpyoNo = props.denpyoNo;
    this.denpyoDate = props.denpyoDate ? props.denpyoDate : null;
    this.client = props.client ? props.client : null;
    this.formatId = props.formatId ? props.formatId : null;
    this.kaiukeId = props.kaiukeId ? props.kaiukeId : null;
    this.biko = props.biko ? props.biko : null;
  }
}

interface VForm extends Vue {
  reset(): void;
  resetValidation(): void;
  validate(): boolean;
}

export default Vue.extend({
  name: "BatchEntryForm",
  components: {
    VRow,
    VCol,
    VForm,
    VTextField,
    VAutocomplete,
    DatePicker
  },
  props: {
    value: {
      type: Object as PropType<BatchEntryFormCls>,
      required: true
    }
  },
  data() {
    return {
      tokuiItems: [] as TokuiGroupExtention[],
      formatItems: [] as PulldownResponse[],
      kaiukeItems: [] as Kaiuke[],
      rules: InputRules,
      isOpen: false,
      content: ""
    };
  },
  computed: {
    modelValue: {
      get(): BatchEntryFormCls {
        return this.value;
      },
      set(newValue: BatchEntryFormCls) {
        this.$emit("input", newValue);
      }
    },
    isBun1: {
      get(): boolean {
        const client = this.modelValue.client;
        const tokuiItems = this.tokuiItems;
        const targetTokui = tokuiItems.find(
          e => e.tokuiId === client?.tokuiId && e.groupId === client?.groupId
        );
        if (targetTokui) {
          return targetTokui.bun1 === 1;
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    "value.client": {
      handler: async function(newValue: TokuiGroupKey | null) {
        if (!newValue) {
          this.formatItems = [];
          this.$emit("change-client", []);
          return;
        }

        const formatHeader = new JyutyuformatHedder({
          formatId: 0,
          tokuiId: newValue.tokuiId,
          tokuiGroup: newValue.groupId
        });

        const tokuiGroupPromise = this.getTokuiGroup(newValue);
        const formatPromise = this.getFormatItems(formatHeader);

        const [tokuiGroup, format] = await Promise.all([
          tokuiGroupPromise,
          formatPromise
        ]);

        this.formatItems = format;
        this.$emit("change-client", tokuiGroup);
      },
      immediate: true
    },
    "value.formatId": {
      handler: async function(newValue: string | null) {
        if (newValue) {
          const key = new JyutyuformatMeisaiKey({
            formatId: Number(newValue),
            tokuiId: 0,
            gyo: 0
          });
          const meisai = await this.getJyutyuMeisai(key);
          this.$emit("change-format", meisai);
        } else {
          this.$emit("change-format", []);
        }
      },
      immediate: true
    }
  },
  methods: {
    async getFormatItems(param: JyutyuformatHedder) {
      const encode = encodeURI(JSON.stringify(param));
      const result = await api.$get<PulldownResponse[]>(
        "/jyutyu/jyutyu/pulldown",
        "query=" + encode
      );
      return result;
    },
    async getTokuiGroup(param: TokuiGroupKey) {
      const result = await api.$post<TokuiGroup[]>(
        api.Paths.batchEntry.getTokuiGroup,
        param
      );
      return result;
    },
    async getJyutyuMeisai(param: JyutyuformatMeisaiKey) {
      const result = await api.$post<JyutyuMeisai[]>(
        api.Paths.batchEntry.getFormat,
        param
      );
      return result;
    },
    convertTokuiGroupKey(item: TokuiGroupExtention) {
      return new TokuiGroupKey(item);
    },
    kaiukeRule(val: unknown) {
      if (this.isBun1) {
        return this.rules.required(val);
      }
      return true;
    },
    validate() {
      const form = this.$refs.form as VForm;
      return form.validate();
    }
  },
  async mounted() {
    const pulldown = await api.$get<PulldownResponse[]>(
      api.Paths.jyutyuTokuiPulldown
    );
    this.tokuiItems = pulldown.map(
      e =>
        new TokuiGroupExtention({
          tokuiId: Number(e.option1),
          groupId: Number(e.option2),
          name: e.name,
          bun1: Number(e.option3)
        })
    );
    const kaiuke = await api.$get<PulldownResponse[]>(
      api.Paths.jyutyuPulldown + "/kaiuke"
    );
    this.kaiukeItems = kaiuke.map(
      e => new Kaiuke({ id: Number(e.code), ukeno: e.name })
    );
  }
});
